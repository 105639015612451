h3 {
  padding-top: 20px;
}

.row {
  margin-bottom: 10px;
}

.btns {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.btns label {
  padding: 5px;
  margin-right: 20px;
  width: 80px;
  border: solid 1px #ccc;
  text-align: center;
  border-radius: 10px;
}

btns2 {
  display: flex;
}

.btns2 label {
  padding: 5px;
  margin-right: 20px;
  border: solid 1px #ccc;
  text-align: center;
  border-radius: 10px;
}

.btns2 input[type="checkbox"] {
  display: none;
}

.btns input[type="radio"] {
  display: none;
}

.btns input[type="checkbox"] {
  display: none;
}

input[type="radio"]:checked + label {
  color: white;
  background-color: #0d6efd;
}

input[type="checkbox"]:checked + label {
  background-color: #0d6efd;
  color: white;
}
