@import url("https://rsms.me/inter/inter.css");
.inter-font {
  font-family: "Inter", sans-serif !important;
}
@supports (font-variation-settings: normal) {
  .inter-font {
    font-family: "Inter var", sans-serif !important;
  }
}

td {
  vertical-align: baseline;
}

.card {
  margin-bottom: 20px;
}

.button-span {
  margin-left: 10px;
}
